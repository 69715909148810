$panel-max-height--medium: 650px;
$view-height--medium__component-max-height: 100%;

// $background-color: #eef0f2;
// $interactive-font-color: #008bd1;

// $background-color--hover: #008bd1;
// $interactive-font-color--hover: #fff;

$font-family: 'Roboto', "Helvetica Neue", Helvetica, Arial, sans-serif !default;

$icomoon-font-path: "../icons/fonts"; // Override for esri/themes/base/icons/style.scss
$calcite-fonts-path: "../fonts/fonts/";

/*
  exlcude the following widgets because the image path of the background-iamges is incorrect and causes an error
*/
$include_Attachments: false;
$include_BasemapToggle:false;
$include_Bookmarks :false;
$include_Directions :false;
$include_FeatureContent :false;
$include_FeatureTable :false;
$include_Legend :false;
$include_Print :false;
$include_Spinner :false;
$include_FeatureTemplates: false;