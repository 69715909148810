.webmap-container {
  @mixin overWriteFont() {
      font-family: "Material Icons" !important;
      font-weight: 400;
      font-style: normal;
      //font-size: 24px;
      // display: inline-block;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      -moz-osx-font-smoothing: grayscale;
      font-feature-settings: "liga";
  }

  .esri-icon {
      &-non-visible {
          @include overWriteFont();

          &:before {
              content: "check_box_outline_blank";
          }
      }
      &-visible {
          @include overWriteFont();

          &:before {
              content: "check_box";
          }
      }
      &-right-triangle-arrow {
          @include overWriteFont();
          &:before {
              content: "chevron_right";
          }
      }
      &-down-arrow {
          @include overWriteFont();
          &:before {
              content: "expand_more";
          }
      }
      &-description {
        // @include overWriteFont();
        // &:before {
        //     content: "help_outline";
        // }

      }
  }
}
