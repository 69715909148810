
html,
body,
#root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;
}
wb-footer {
  margin-top: auto;
}

main {
  display: flex;
  flex: 1;
}
.webmap-container {
  position: relative;
  flex: 1 0 auto;
  overflow: hidden;
  &-inner {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
.webmap-container {
  height: 100%;
  .esri-ui-top-left,
  .esri-ui-top-right,
  .esri-ui-bottom-right,
  .esri-ui-bottom-left {
    max-height: 100%;
  }
}
.wb-modal {
  &__label {
    display: none;
  }
  &__body__content {
    text-align: left;
  }
  width: auto;
  height: auto;
}