.custom-esri-search {

    &--button {
        right: 0;
        z-index: 1;
        margin: 0;
    }
    .esri-search {
        &__input {
            padding: 0 12px;
        }
        &__clear-button {
            display: none;
        }
        &__suggestions-list .esri-menu__list-item {
            position: relative;
            text-align: left;
        }
    }
    .esri-icon-search:before {
        display: block;
        width: 22px;
        height: 22px;
        line-height: 22px;
    }
}
