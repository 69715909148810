.wb-header__togglemenu {
    display: none;
}
.wb-footer {
    z-index: -1;
}

.webmap-container {
    .esri-layer-list {
        box-shadow: none;
        padding: 0;

        &__list {
            &--root {
                padding: 0;

                > li > .esri-layer-list__item-container {
                    background-color: var(--wb-zc5);
                    color: var(--wb-background-white);
                    font-weight: 500;

                        //code to hide checkbox in group level
                        .esri-layer-list__item-label {
                            pointer-events: none;
                        }
                        .esri-layer-list__item-toggle {
                            display: none;
                        }
                        .esri-layer-list__item-actions-menu {
                            display: none;
                        }
                        .esri-layer-list__item-actions-menu-item {
                            color: var(--wb-background-white);
                        }
                    }
                }
                .esri-layer-list__item-actions-menu {
                    order: -1;
                }
        }
        &__child-toggle {
            width: auto;
            span {
                font-size: 1.5rem;
                color: var(--wb-background-white);
            }
        }
        &__item {
            background-color: transparent;
            margin-top: 0;
            margin-bottom: 0;
            &-container {
                flex-direction: row-reverse;
                padding-left: $side-spacing--half;
                align-items: center;
            }

            &-title {
                text-align: left;
            }
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .esri-expand__content {
        overflow: auto;
    }
}

.esri {
    &-ui-top-right,
    &-ui-top-left {
        height: 100%;
    }
    &-zoom {
        order: -3;
    }
    &-locate {
        order: -1;
    }
    &-view {
        &-width-xsmall .esri-expand--auto .esri-expand__container--expanded {
            padding-top: 5rem;
        }

        &-width-greater-than-xsmall {
            .esri-expand__container {
                position: static;
            }
            .esri-expand--auto .esri-expand__content {
                overflow: auto;
            }
        }
    }
    &-widget--button {
        position: relative;

        &:before {
            content: attr(title);
            display: none;
            position: absolute;
            line-height: 1rem;
            right: 100%;
            background-color: var(--wb-blue);
            color: var(--wb-background-white);
            top: 50%;
            transform: translateY(-50%);
            white-space: nowrap;
            padding: 0.25rem 0.5rem;
            margin-right: .5rem;
            opacity: 0;
            transition: all 0.3s;
            transition-delay: 1s;

            .esri-ui-top-left & {
                margin-right: 0;
                margin-left: .5rem;
                right: auto;
                left: 100%;
            }
        }
        &:hover {
            overflow: visible;

            &:before {
                opacity: 1;
            }
        }
    }
    &-menu__list-item {
        text-align: left;
    }
}

.custom-widget {
    &-Legend {
        order: 1;
    }

    &-Search {
        order: -2;

        .esri-expand__content.esri-expand__content--expanded {
            top: 0;
            overflow: visible;
        }
    }
}

.esri-home {
    order: -99;
}