.esri-component {

    .widget--collapse {
        display: flex;

        &__checkbox {
            display: none;

            &:checked {

                ~ .widget--collapse__label {
                    background-color:var(--blue);

                    .widget--collapse__icon {

                        &--open {
                            display: none;
                        }
                        &--close {
                            display: block;
                        }
                    }
                }
                ~ .widget--collapse__content {
                    width: 340px;
                    // 32+15+12 *2
                    max-width: calc(100vw - 118px);
                    display: block;
                    position: absolute;
                    right: 100%;
                    margin: 0 12px;
                    top: 0;
                    max-height: 100%;
                    overflow: auto;

                    .esri-ui-top-left & {
                        right: auto;
                        left: 100%;
                    }
                }
            }
        }
        &__label {
            margin-bottom: 0;
            order: 999;
        }
        &__icon {
            &--open {
                display: block;
            }
            &--close {
                display: none;
            }
        }
        &__content {
            display: none;
            width: 0;
            margin-right: 0;
            transition: width 0.25s ease;
            overflow: hidden;
            text-align: left;
        }
    }
}
.esri-widget--button {
    color: #008bd1;
}
